import { Injectable } from '@angular/core'
import { LocaleFragment, LocalesQueryService } from '@app-graphql/api-schema'
import { AuthService } from '@app/services/auth/auth.service'
import { RequiresInitialization } from '@app/types/framework.types'
import { Storage } from '@ionic/storage'
import { TranslateService } from '@ngx-translate/core'
import { Apollo } from 'apollo-angular'
import { BehaviorSubject, firstValueFrom } from 'rxjs'
import { map } from 'rxjs/operators'

@Injectable({
    providedIn: 'root',
})
export class LanguageService implements RequiresInitialization {

    private preferredLanguage$$ = new BehaviorSubject<string | null>(null)
    public preferredLanguage$ = this.preferredLanguage$$.asObservable()
    public locales$ = new BehaviorSubject<readonly LocaleFragment[]>([])

    constructor(
        private readonly storage: Storage,
        private readonly translateService: TranslateService,
        private readonly localesQueryService: LocalesQueryService,
        private readonly authService: AuthService,
        private readonly apollo: Apollo,
    ) {

    }

    public async initialize() {
        try {
            const locales = await this.getLanguages()
            const user = this.authService.getUser()

            this.locales$.next(locales)

            // Set the available languages of the translateService to the same list as
            // the available locales, for use with the static translations.
            this.translateService.addLangs(this.locales$.getValue().map(locale => locale.name))

            await this.setLanguage((user?.preferredLanguage ?? await this.storage.get('preferred-language')) || null)

            // If only one language is available this is automatically set as the
            // preferred language, to skip the language selection page.
            if (this.locales$.getValue().length === 1) await this.setLanguage(this.locales$.getValue()[0].name)
        } catch {
            console.error('No valid country/language configuration was found')
        }
    }

    public async getLanguages() {
        return firstValueFrom(this.localesQueryService.fetch(undefined, {
            fetchPolicy: 'network-only',
        }).pipe(
            map((result) => result.data.locales),
        ))
    }

    public getSelectedLanguage() {
        return this.preferredLanguage$$.getValue()
    }

    public async setLanguage(language: string): Promise<void> {
        if (this.getSelectedLanguage() && language !== this.getSelectedLanguage()) {
            await this.apollo.client.resetStore()
        }

        this.preferredLanguage$$.next(language)

        if (language) {
            this.translateService.use(language)
            await this.storage.set('preferred-language', language)
        }
    }

    public hasSelectedLanguage(): boolean {
        return this.preferredLanguage$$.getValue() !== null
    }

}
