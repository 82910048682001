import { InjectionToken, Injector, NgModule } from '@angular/core'
import { ApolloClientOptions, ApolloLink, InMemoryCache } from '@apollo/client/core'
import { APOLLO_NAMED_OPTIONS, APOLLO_OPTIONS, ApolloModule } from 'apollo-angular'
import { AuthStorageService } from '@app/services/auth-storage/auth-storage.service'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient } from 'graphql-ws'
import {
    createApiAuthErrorLink,
    createApiAuthLink,
    createApiHttpLink,
    createApiLangLink,
    createApiLogLink,
    createNetworkErrorLink,
} from '@app/modules/graphql/graphql.module.lib'
import { SafeGuardsService } from '@app/services/safe-guards/safe-guards.service'
import { NamedOptions } from 'apollo-angular/types'
import { CountryService } from '@app/services/country/country.service'
import { pubsubGraphQlUrl } from '@lib/env/env.lib'
import { Observable, Subject } from 'rxjs'

export const GraphQLApiCache = new InMemoryCache()

export function createApiClientOptions(
    countryService: CountryService,
    injector: Injector,
    authStorageService: AuthStorageService,
    safeGuardsService: SafeGuardsService,
): ApolloClientOptions<any> {
    return {
        cache: GraphQLApiCache,
        link: ApolloLink.from([
            createNetworkErrorLink(safeGuardsService),
            createApiAuthErrorLink(injector),
            createApiAuthLink(authStorageService),
            createApiLangLink(injector),
            createApiLogLink(),
            createApiHttpLink(countryService),
        ]),
    }
}

export const WEBSOCKET_REFRESHED = new InjectionToken<Observable<void>>('WEBSOCKET_REFRESHED')

@NgModule({
    imports: [
        ApolloModule,
    ],
    providers: [
        {
            provide: APOLLO_OPTIONS,
            useFactory: createApiClientOptions,
            deps: [
                CountryService,
                Injector,
                AuthStorageService,
                SafeGuardsService,
            ],
        },
        {
            provide: WEBSOCKET_REFRESHED,
            useValue: new Subject<void>(),
        },
        {
            provide: APOLLO_NAMED_OPTIONS,
            useFactory: (
                countryService: CountryService,
                refreshTriggers: Subject<void>,
            ): NamedOptions => ({
                pubsub: {
                    name: 'pubsub',
                    cache: new InMemoryCache(),
                    link: new GraphQLWsLink(
                        createClient({
                            url: () => pubsubGraphQlUrl(countryService.resolveSelectedCountryCode()),
                            shouldRetry: () => true,
                            on: {
                                opened: () => refreshTriggers.next(),
                                closed: () => console.trace('GraphQLWsLink closed'),
                            },
                        }),
                    ),
                },
            }),
            deps: [CountryService, WEBSOCKET_REFRESHED],
        },
    ],
})
export class GraphQLModule {
}
