import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core'
import { BehaviorSubject } from 'rxjs'

@Component({
    selector: 'app-element-switch-animation',
    templateUrl: './element-switch-animation.component.html',
    styleUrls: ['./element-switch-animation.component.scss'],
})
export class ElementSwitchAnimationComponent implements AfterViewInit {

    private readonly currentSlide: BehaviorSubject<number> = new BehaviorSubject(0)

    @ViewChild('animationContainer')
    private readonly animationContainer: ElementRef

    private slides: HTMLElement[]

    @Input()
    public animationType: 'scroll' | 'fade' = 'fade'

    ngAfterViewInit() {
        const classes = []
        classes.push(this.animationType)
        this.animationContainer.nativeElement.classList.add(classes)

        this.slides = Array.from(this.animationContainer.nativeElement.children)

        this.currentSlide.subscribe(slideNumber => {
            this.slides.forEach(slide => slide.className = 'animation__slide')
            if (this.slides.length < 1) return
            this.slides[slideNumber].classList.add('animation__active')
            this.slides[slideNumber - 1 >= 0 ? slideNumber - 1 : this.slides.length - 1 ].classList.add('animation__prev')
            this.slides[slideNumber + 1 >= this.slides.length ? 0 : slideNumber + 1 ].classList.add('animation__next')
        })
    }

    public nextSlide() {
        if (this.currentSlide.value === this.slides.length - 1) {
            this.currentSlide.next(0)
        } else {
            this.currentSlide.next(this.currentSlide.value + 1)
        }
    }
}
