import { Injectable } from '@angular/core'
import { RequiresInitialization } from '@app/types/framework.types'
import { Env } from '@env/environment.types'
import { Storage } from '@ionic/storage'
import { expectNonNil } from '@lib/assertions/assertions.lib'
import { TranslateService } from '@ngx-translate/core'
import { isNil } from 'ramda'
import { BehaviorSubject } from 'rxjs'
import { distinctUntilChanged, map } from 'rxjs/operators'

@Injectable({
    providedIn: 'root',
})
export class CountryService implements RequiresInitialization {

    public readonly countryNames: { readonly [K in Env.CountryCode]: string } = {
        nl: 'Netherlands',
        de: 'Germany',
    }

    private readonly countryCode$$ = new BehaviorSubject<Env.CountryCode | null>(null)
    public readonly countryCode$ = this.countryCode$$.pipe(distinctUntilChanged())
    public readonly countryName$ = this.countryCode$$.pipe(
        map((code) => code ? this.countryNames[code] : null),
    )

    constructor(
        private storage: Storage,
        private translateService: TranslateService,
    ) {
    }

    public async initialize(): Promise<void> {
        this.translateService.setDefaultLang('en')
        const code = await this.getStoredCountryCode()

        if (isNil(code)) return
        
        this.countryCode$$.next(code)
    }

    /**
     * Returns the user-selected {@link Env.CountryCode country code} if any is set. Returns `null` otherwise.
     */
    public getSelectedCountryCode(): Env.CountryCode | null {
        return this.countryCode$$.getValue()
    }

    /**
     * Returns the user-selected {@link Env.CountryCode country code} if any is set. Throws an error if none is set.
     */
    public resolveSelectedCountryCode(): Env.CountryCode {
        return expectNonNil(this.getSelectedCountryCode(), 'Failed to resolve a set country code!')
    }

    public hasSelectedCountry(): boolean {
        return this.countryCode$$.getValue() !== null
    }

    public hasNoSelectedCountry(): boolean {
        return this.countryCode$$.getValue() === null
    }

    public async setCountryCode(isoAlpha2Code: Env.CountryCode): Promise<void> {
        await this.storage.set('selected-country', isoAlpha2Code)
        this.countryCode$$.next(isoAlpha2Code)
    }

    public async getStoredCountryCode(): Promise<Env.CountryCode | null> {
        return this.storage.get('selected-country')
    }
}
