import { AfterViewInit, Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core'
import { FormBuilder, FormControl, Validators } from '@angular/forms'
import { Router } from '@angular/router'
import { LocaleFragment } from '@app-graphql/api-schema'
import {
    ElementSwitchAnimationComponent,
} from '@app/domains/ui/components/element-switch-animation/element-switch-animation.component'
import { ImageOption } from '@app/domains/ui/components/image-select/image-select.types'
import { LanguageService } from '@app/services/language/language.service'
import Bugsnag from '@bugsnag/js'
import { delay, errorMessage } from '@lib/common.lib'
import { TranslateService } from '@ngx-translate/core'
import { isNil } from 'ramda'
import { map, Observable, of } from 'rxjs'

@Component({
    selector: 'app-language-select',
    templateUrl: './language-select-page.component.html',
    styleUrls: ['./language-select-page.component.scss'],
})
export class LanguageSelectPage implements OnInit, AfterViewInit, OnDestroy {

    public multiLanguageLabels = {
        'en': {
            submitButton: 'Continue',
            selectLabel: 'Select your language',
        },
        'fr': {
            submitButton: 'continuer',
            selectLabel: 'Sélectionnez votre langue',
        },
        'de': {
            submitButton: 'Weiter',
            selectLabel: 'Wählt Ihre Sprache',
        },
    }

    @ViewChildren(ElementSwitchAnimationComponent)
    private readonly switchAnimations: QueryList<ElementSwitchAnimationComponent>

    public control: FormControl<string | null>
    public processing: boolean = false
    public languageSelected: Observable<any>

    private locales$: Observable<readonly LocaleFragment[]>

    public options$: Observable<ImageOption[]>

    private intervalId: ReturnType<typeof setInterval>

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly router: Router,
        private readonly languageService: LanguageService,
        private readonly translateService: TranslateService,
    ) {

    }

    public async ngOnInit(): Promise<void> {
        this.control = this.formBuilder.control(this.languageService.getSelectedLanguage(), [Validators.required])

        this.locales$ = of(await this.languageService.getLanguages())

        this.options$ = this.locales$.pipe(
            map(locales => (locales.map((locale) => ({
                value: locale.name,
                label: this.translateService.instant('languages.' + locale.name),
            })))),
        )

        this.languageSelected = this.languageService.preferredLanguage$.pipe(
            map(language => ! isNil(language)),
        )
    }

    public ngAfterViewInit(): void {

        this.intervalId = setInterval(() => {
            this.switchAnimations.forEach(switchAnimation => switchAnimation.nextSlide())
        }, 3000)
    }

    public ngOnDestroy(): void {
        clearInterval(this.intervalId)
    }

    public async setSelectedLanguage(): Promise<void> {
        if (! this.control.value) {
            return
        }

        this.processing = true

        try {
            await this.languageService.setLanguage(this.control.value)

            // The delay is a hack to give connection check streams that are derived from the
            // country code stream the opportunity to update before we attempt to navigate to
            // other application pages, which should all be guarded behind a successful API
            // availability check.
            await delay(500)

            await this.router.navigateByUrl('/')
        } catch (err) {
            Bugsnag.notify(errorMessage(err))
        } finally {
            this.processing = false
        }

    }

    protected readonly Object = Object
}
