<app-auth-template [withCountrySelectionLink]="false">

    <ion-label>
        <ion-icon name="globe-outline" aria-hidden="true" size="large" color="tertiary"></ion-icon>
        <app-element-switch-animation animationType="fade">
            @for (language of Object.values(languages); track language) {
                <span>{{ language.selectLabel }}</span>
            }
        </app-element-switch-animation>
    </ion-label>

    <app-image-select
        [formControl]="control"
        [label]="'country-select-page.select-label' | translate"
        [options]="options"
    />

    <ion-button
        [disabled]="processing || control.invalid"
        (click)="setSelectedCountry()"
        expand="block"
    >
        <span [hidden]="processing">
            <app-element-switch-animation animationType="fade">
                    @for (language of Object.values(languages); track language) {
                        <span>{{ language.submitButton }}</span>
                    }
            </app-element-switch-animation>
        </span>
        <ion-spinner [hidden]="! processing" />
    </ion-button>
</app-auth-template>
