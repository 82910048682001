import {
    AfterViewInit,
    ChangeDetectionStrategy,
    Component,
    Input,
    OnDestroy,
    QueryList,
    ViewChildren,
} from '@angular/core'
import {
    ElementSwitchAnimationComponent,
} from '@app/domains/ui/components/element-switch-animation/element-switch-animation.component'
import { CountryService } from '@app/services/country/country.service'

@Component({
    selector: 'app-auth-template',
    templateUrl: './auth-template.component.html',
    styleUrls: ['./auth-template.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuthTemplateComponent implements AfterViewInit, OnDestroy {

    @Input()
    public headerTranslationKey: string

    @Input()
    public imageUrl: string = '/assets/images/jp-people.png'

    /**
     * Determines whether the "Login" and "Create account" segment buttons are shown
     */
    @Input()
    public withSegmentButtons: boolean = false

    /**
     * Determines which of the segment buttons should be considered active.
     *
     * Has no effect when {@link withSegmentButtons [withSegmentButtons]}="false".
     */
    @Input()
    public activeSegmentButton: 'login' | 'register' = 'login'

    @Input()
    public withCountrySelectionLink: boolean = true

    public languages = {
        'en': {
            title: 'Ready to Join Program?',
        },
        'fr': {
            title: 'Prêt pour Join Program?',
        },
        'de': {
            title: 'Bereit für Join Program?',
        },
    }

    @ViewChildren(ElementSwitchAnimationComponent)
    private readonly switchAnimations: QueryList<ElementSwitchAnimationComponent>

    private intervalId: ReturnType<typeof setInterval>

    constructor(
        public readonly countryService: CountryService,
    ) {
    }

    public ngAfterViewInit(): void {

        this.intervalId = setInterval(() => {
            this.switchAnimations.forEach(switchAnimation => switchAnimation.nextSlide())
        }, 3000)
    }

    public ngOnDestroy(): void {
        clearInterval(this.intervalId)
    }

    protected readonly Object = Object
}
