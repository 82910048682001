import { NgModule } from '@angular/core'
import { PreloadAllModules, RouterModule, Routes } from '@angular/router'
import { hasCountrySelection } from '@app/guards/has-country-selection/has-country-selection.guard'
import { hasLanguageSelection } from '@app/guards/has-language-selection/has-language-selection.guard'
import { CountrySelectPage } from '@app/pages/country-select/country-select.page'
import { SharedModule } from '@app/modules/shared/shared.module'
import { ConsentPoliciesComponent } from '@app/pages/consent-policies/consent-policies.component'
import { LanguageSelectPage } from '@app/pages/language-select/language-select-page.component'

const routes: Routes = [
    {
        path: 'select-country',
        component: CountrySelectPage,
    },
    {
        path: 'select-language',
        canMatch: [hasCountrySelection],
        component: LanguageSelectPage,
    },
    {
        path: 'consent-policies',
        component: ConsentPoliciesComponent,
    },
    {
        path: '',
        canMatch: [hasCountrySelection, hasLanguageSelection],
        loadChildren: () => import('@app/pages/country-scoped/country-scoped.module').then(
            (m) => m.CountryScopedModule,
        ),
    },
]

@NgModule({
    declarations: [
        CountrySelectPage,
        LanguageSelectPage,
        ConsentPoliciesComponent,
    ],
    imports: [
        SharedModule,
        RouterModule.forRoot(routes, {
            preloadingStrategy: PreloadAllModules,
            initialNavigation: 'disabled',
        }),
    ],
    exports: [
        RouterModule,
    ],
})
export class AppRoutingModule {
}
