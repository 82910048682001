<form>
    <ion-header>
        <ion-toolbar>
            <ion-title>
                Select your country
            </ion-title>
        </ion-toolbar>
    </ion-header>

    <ion-radio-group [formControl]="control">
        @if (options) {
            @for (option of options; track option.value) {
                <ion-item lines="none" color="none" class="radio-button-item">
                    <ion-radio
                        color="primary"
                        label-placement="end"
                        slot="start"
                        [value]="option.value"
                    >
                        <div class="option">
                            @if (option.image) {
                                <img [src]="option.image" alt="">
                            }
                            <span>{{ option.label }}</span>
                        </div>
                    </ion-radio>
                </ion-item>
            }
        }
    </ion-radio-group>

    <ion-footer class="ion-no-border">
        <ion-toolbar color="light">
            <ion-buttons slot="secondary">
                <ion-button
                    color="primary"
                    fill="clear"
                    [strong]="true"
                    (click)="cancel()"
                >
                    {{ "common.cancel" | translate }}
                </ion-button>
            </ion-buttons>
            <ion-buttons slot="primary">
                <ion-button
                    color="primary"
                    fill="clear"
                    [strong]="true"
                    (click)="save()"
                >
                    {{ "common.okay" | translate }}
                </ion-button>
            </ion-buttons>
        </ion-toolbar>
    </ion-footer>
</form>