import { Injectable } from '@angular/core'
import { Router } from '@angular/router'
import { AuthService } from '@app/services/auth/auth.service'
import { CapacityService } from '@app/services/capacity/capacity.service'
import { CartService } from '@app/services/cart/cart.service'
import { CheckoutInputService } from '@app/services/checkout/checkout-input/checkout-input.service'
import { ConnectionService } from '@app/services/connection/connection.service'
import { CountryService } from '@app/services/country/country.service'
import { DeeplinksService } from '@app/services/deeplinks/deeplinks.service'
import { FeedFilterService } from '@app/services/feed-filter/feed-filter.service'
import { LanguageService } from '@app/services/language/language.service'
import { PaymentService } from '@app/services/payment/payment.service'
import { PlatformVersionsService } from '@app/services/platform-versions/platform-versions.service'
import { ThemingService } from '@app/services/theming/theming.service'
import { SplashScreen } from '@capacitor/splash-screen'
import { StatusBar, Style } from '@capacitor/status-bar'
import { Platform } from '@ionic/angular'
import { Storage } from '@ionic/storage'

@Injectable({
    providedIn: 'root',
})
export class BootService {
    constructor(
        private readonly authService: AuthService,
        private readonly cartService: CartService,
        private readonly checkoutInputService: CheckoutInputService,
        private readonly connectionService: ConnectionService,
        private readonly platform: Platform,
        private readonly platformVersionsService: PlatformVersionsService,
        private readonly router: Router,
        private readonly storage: Storage,
        private readonly deeplinksService: DeeplinksService,
        private readonly paymentService: PaymentService,
        private readonly themingService: ThemingService,
        private readonly capacityService: CapacityService,
        private readonly feedFilterService: FeedFilterService,
        private readonly countryService: CountryService,
        private readonly languageService: LanguageService,
    ) {
    }

    /**
     * Performs all (sync as well as async) tasks required to run the app.
     * Returns a promise that resolves once everything is set up.
     */
    public async bootApplication(): Promise<void> {
        // 1. Await the platform to be ready first, so that any plugin may call
        //    native functionality. For cordova this promise settles on the
        //    deviceready event.
        await this.platform.ready()

        // 2. Wait for the creation of local storage, along with the initialization of countryService and authService,
        //    before proceeding to initialize any services that may depend on them.
        await Promise.all([
            await this.storage.create(),
            await this.countryService.initialize(),
            await this.initializeAuth(),
        ])

        // 3. Wait for the initialization of the languageService, because all services
        //    that fetch data need the language header to be set.
        await this.languageService.initialize()

        await Promise.all([
            this.capacityService.initialize(),
            this.initializeCarts().then(() => this.initializeCheckout()),
            this.initializePayment(),
            this.initializeDeeplinks(),
            this.initializeThemingService(),
            this.configureStatusBarAppearance(),
            this.guardNetworkConnection(),
            this.handleAppUpdate(),
            this.handleTestForNewUpdates(),
            this.initializeFeedFilter(),
        ])

        this.router.initialNavigation()
        await SplashScreen.hide()
    }

    private async initializeAuth(): Promise<void> {
        await this.authService.initialize()
    }

    private async initializeCarts(): Promise<void> {
        await this.cartService.initialize()
    }

    private async initializeCheckout(): Promise<void> {
        await this.checkoutInputService.initialize()
    }

    private async initializePayment(): Promise<void> {
        await this.paymentService.initialize()
    }

    private async initializeFeedFilter(): Promise<void> {
        await this.feedFilterService.initialize()
    }

    private async handleTestForNewUpdates(): Promise<void> {
        return this.platformVersionsService.checkForNewUpdates()
    }

    /**
     * When the app has just been updated we can see that by comparing the stored version against the version
     * read from package.json. Upon update, we'll clear carts from storage to prevent
     * data incompatibility issues.
     */
    private async handleAppUpdate(): Promise<void> {
        const storageKey = this.platformVersionsService.APP_VERSION_STORAGE_KEY
        const storedVersion: string | null = await this.storage.get(storageKey)
        const currentVersion: string = this.platformVersionsService.getAppVersion()

        if (storedVersion !== currentVersion) {
            await Promise.all([
                this.cartService.clearAllCarts(),
                this.storage.set(storageKey, currentVersion),
            ])
        }
    }

    /**
     * Guards the app for network loss. This initialization step makes sure that the app
     * redirects to a network error page upon disconnect, and redirects back to the
     * previous URL once network connection is re-established.
     */
    private async guardNetworkConnection(): Promise<void> {
        await this.connectionService.initialize()
    }

    /**
     * Sets up the theming service.
     */
    private async initializeThemingService(): Promise<void> {
        await this.themingService.initialize()
    }

    /**
     * Configures the status bar appearance for Android and iOS environments.
     */
    private async configureStatusBarAppearance(): Promise<void> {
        if (this.platform.is('hybrid')) {
            await StatusBar.setStyle({ style: Style.Dark })

            // Android only (not implemented on iOS)
            if (this.platform.is('android')) {
                await StatusBar.setBackgroundColor({ color: '#51C796' })
            }
        }
    }

    /**
     * Registers the deeplink routes to the deeplinks service, and attaches the corresponding
     * handler procedures.
     */
    private async initializeDeeplinks(): Promise<void> {
        await this.deeplinksService.initialize()
    }
}
