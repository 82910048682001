import { Injectable } from '@angular/core'
import { FetchResult } from '@apollo/client'
import {
    ClientSuggestionFragment,
    ClientSuggestionQueryService,
    ConfirmTwoFactorCodeMutationService,
    ConfirmTwoFactorCodeMutationVariables,
    DisableTwoFactorMutation,
    DisableTwoFactorMutationService,
    EnableTwoFactorMutation,
    EnableTwoFactorMutationService,
    ForgotPasswordMutationService,
    LocalLoginMutationService,
    LogoutMutationService,
    MeQueryService,
    RefreshResponseFragment,
    RefreshTokenMutationService,
    RegisterMutationService,
    RegisterMutationVariables,
    RegisterResponse,
    RegisterStatuses,
    RoleNameEnum,
    ShortcodeRegisterInput,
    ShortcodeRegisterMutationService,
    UpdateForgottenPasswordMutationService,
    UpdateForgottenPasswordMutationVariables,
    UpdateMeMutationService,
    UserFragment,
} from '@app-graphql/api-schema'
import { LoginError } from '@app/errors/auth/LoginError'
import { RefreshTokenError } from '@app/errors/auth/RefreshTokenError'
import { RegistrationError } from '@app/errors/auth/RegistrationError'
import { AuthStorageService } from '@app/services/auth-storage/auth-storage.service'
import { ConnectionService } from '@app/services/connection/connection.service'
import { CountryService } from '@app/services/country/country.service'
import type { RequiresInitialization } from '@app/types/framework.types'
import { forceFreshFetch } from '@lib/apollo/apollo.lib'
import { authPayloadToAuthState, AuthState, isValidAuthPayload } from '@lib/auth/auth.lib'
import { delay, errorMessage } from '@lib/common.lib'
import { apiClientCredentials } from '@lib/env/env.lib'
import { Apollo } from 'apollo-angular'
import { isNil } from 'ramda'
import { BehaviorSubject, firstValueFrom, Observable } from 'rxjs'
import { distinctUntilChanged, map } from 'rxjs/operators'

@Injectable({
    providedIn: 'root',
})
export class AuthService implements RequiresInitialization {
    private readonly userSubject = new BehaviorSubject<UserFragment | null>(null)

    /**
     * Reference to a promise resolving to a new access token. Only defined while a refresh
     * is pending.
     */
    private pendingTokenRefresh?: Promise<string>

    /**
     * Stream of up-to-date user data over time. Emits null if the user is unauthenticated.
     */
    public readonly user$: Observable<UserFragment | null> = this.userSubject.asObservable()

    /**
     * Stream of the current client shortcode over time.
     */
    public readonly clientShortcode$: Observable<string | null> = this.user$.pipe(
        map((user) => user ? user.client.registrationShortcode : null),
        distinctUntilChanged(),
    )

    constructor(
        private readonly countryService: CountryService,
        private readonly localLoginMutationService: LocalLoginMutationService,
        private readonly logoutMutationService: LogoutMutationService,
        private readonly forgotPasswordMutationService: ForgotPasswordMutationService,
        private readonly refreshTokenMutationService: RefreshTokenMutationService,
        private readonly registerService: RegisterMutationService,
        private readonly shortcodeRegisterMutationService: ShortcodeRegisterMutationService,
        private readonly updateForgottenPasswordService: UpdateForgottenPasswordMutationService,
        private readonly enableTwoFactorService: EnableTwoFactorMutationService,
        private readonly disableTwoFactorService: DisableTwoFactorMutationService,
        private readonly confirmTwoFactorService: ConfirmTwoFactorCodeMutationService,
        private readonly authStorageService: AuthStorageService,
        private readonly meService: MeQueryService,
        private readonly clientSuggestionQueryService: ClientSuggestionQueryService,
        private readonly connection: ConnectionService,
        private readonly apollo: Apollo,
        private readonly updateMeService: UpdateMeMutationService,
    ) {
    }

    public async initialize(): Promise<void> {
        const state = await this.authStorageService.initialize()

        if (state === null) {
            return
        }

        if (this.connection.apiIsOnline()) {
            await this.refreshUser()
        } else {
            // If we have no API connection then defer user load. We can also not
            // await this procedure because that will block the initial navigation to
            // safeguard paths.
            this.connection
                .awaitApiOnline()
                .then(() => delay(300))
                .then(() => this.refreshUser())
        }
    }

    // ------------------------------------------------------------------------------
    //      State accessors and assertions
    // ------------------------------------------------------------------------------

    public isAuthenticated(): boolean {
        return this.authStorageService.hasAuthState()
            && this.getUser() !== null
    }

    public isUnauthenticated(): boolean {
        return ! this.isAuthenticated()
    }

    public getUser(): UserFragment | null {
        return this.userSubject.getValue()
    }

    public getClientId(): string | null {
        return this.userSubject.getValue()?.client.id ?? null
    }

    /**
     * Determines if the authenticated user has the given role. Returns false if unauthenticated.
     */
    public userHasRole(role: RoleNameEnum): boolean {
        const user = this.getUser()

        return isNil(user)
            ? false
            : user.roles.some(({ name }) => name === role)
    }

    // ------------------------------------------------------------------------------
    //      Manual updates
    // ------------------------------------------------------------------------------

    /**
     * Re-fetches the user data and pushes the new data into the user observable streams.
     */
    public async refreshUser(): Promise<UserFragment | null> {
        const user = await this.fetchUser()
        this.userSubject.next(user)
        return user
    }

    private async fetchUser(): Promise<UserFragment | null> {
        if (! this.authStorageService.hasAuthState()) {
            return null
        }

        try {
            const result = await firstValueFrom(
                this.meService.fetch(undefined, {
                    fetchPolicy: forceFreshFetch(),
                    errorPolicy: 'all',
                }),
            )

            return result.data.me
        } catch (error: unknown) {
            // We will end up in this catch clause if the current access- and refresh-tokens
            // are both expired or don't exist.
            return null
        }
    }

    // ------------------------------------------------------------------------------
    //      Registration & Authentication flows
    // ------------------------------------------------------------------------------

    /**
     * @throws {RegistrationError}
     */
    public async register(input: RegisterMutationVariables): Promise<{ status: RegisterStatuses }> {
        const result = await firstValueFrom(
            this.registerService.mutate(input, { errorPolicy: 'all' }),
        )

        if (result.errors?.length) {
            throw new RegistrationError(result.errors[0])
        }

        return result.data!.register
    }

    /**
     * @throws {RegistrationError}
     */
    public async shortcodeRegister(input: ShortcodeRegisterInput): Promise<RegisterResponse> {
        const result = await firstValueFrom(
            this.shortcodeRegisterMutationService.mutate({ input }, { errorPolicy: 'all' }),
        )

        if (result.errors?.length) {
            throw new RegistrationError(result.errors[0])
        }

        return result.data!.shortcodeRegister
    }

    public async loginAndRefreshUser(
        email: string,
        password: string,
        preferredLanguage: string | null,
    ): Promise<{ state: AuthState; user: UserFragment }> {
        const state = await this.login(email, password)
        await this.authStorageService.submitAuthState(state)

        await firstValueFrom(this.updateMeService.mutate({
            input: {
                preferredLanguage,
            },
        }))

        const user = await this.refreshUser()

        if (user === null) {
            throw new Error('Failed to fetch user information after login')
        }

        return { state, user }
    }

    private async login(email: string, password: string): Promise<AuthState> {
        const { clientId, clientSecret } = apiClientCredentials(
            this.countryService.resolveSelectedCountryCode(),
        )

        const result = await firstValueFrom(this.localLoginMutationService.mutate({
            clientId,
            clientSecret,
            password,
            username: email,
        }, { errorPolicy: 'all' }))

        if (result.errors?.length) {
            throw new LoginError(result.errors[0])
        }

        const payload = result.data?.localLogin

        if (! isValidAuthPayload(payload)) {
            throw new Error('Got invalid Auth payload from local login request.')
        }

        return authPayloadToAuthState(payload)
    }

    /**
     * A last resort method to flush all authentication data (user and tokens) whenever
     * the application hits a state where authentication cannot be recovered. This is the
     * case when access- and/or refresh-tokens are revoked on the server, or can no longer
     * be found. In such cases we can not use the logout mutation, since that requires the
     * user to authorize with a valid access token too.
     */
    public async flushAuthData(): Promise<void> {
        await this.authStorageService.clearAuthState()
        await this.apollo.client.resetStore()
        this.userSubject.next(null)
    }

    public async logout(): Promise<void> {
        await firstValueFrom(this.logoutMutationService.mutate())
        await this.authStorageService.clearAuthState()
        await this.apollo.client.resetStore()
        this.userSubject.next(null)
    }

    public async forgotPassword(email: string): Promise<string | undefined> {
        const x = await firstValueFrom(this.forgotPasswordMutationService.mutate({ email }))
        return x.data?.forgotPassword
    }

    public updateForgottenPassword(input: UpdateForgottenPasswordMutationVariables) {
        return this.updateForgottenPasswordService.mutate(input)
    }

    public enableTwoFactor(): Observable<FetchResult<EnableTwoFactorMutation>> {
        return this.enableTwoFactorService.mutate()
    }

    public disableTwoFactor(): Observable<FetchResult<DisableTwoFactorMutation>> {
        return this.disableTwoFactorService.mutate()
    }

    public confirmTwoFactor(input: ConfirmTwoFactorCodeMutationVariables) {
        return this.confirmTwoFactorService.mutate(input, {
            errorPolicy: 'all',
        })
    }

    public getClientSuggestion(email: string): Observable<ClientSuggestionFragment | undefined> {
        return this.clientSuggestionQueryService.fetch({ email }).pipe(
            map((result) => result.data.clientSuggestion ?? undefined),
        )
    }

    public refreshAccessToken(): Promise<string> {
        return this.pendingTokenRefresh ??= this.doRefreshAccessToken()
    }

    /**
     * Closed procedure that performs a token refresh operation: Takes the refresh token from
     * the current state, fetches new tokens, stores them locally and pushes them into the
     * observable streams.
     * @throws {RefreshTokenError}
     */
    private async doRefreshAccessToken(): Promise<string> {
        const state = this.authStorageService.getAuthState()

        if (state === null) {
            throw new RefreshTokenError('Invalid attempt to refresh access token.')
        }

        let payload: RefreshResponseFragment | undefined

        try {
            payload = await firstValueFrom(
                this.refreshTokenMutationService
                    .mutate({ input: { refresh_token: state.refreshToken } })
                    .pipe(map((result) => {
                        return result.data?.refreshToken
                    })),
            )
        } catch (error: any) {
            throw new RefreshTokenError(errorMessage(error))
        }

        if (! isValidAuthPayload(payload)) {
            throw new RefreshTokenError('Got invalid Auth payload from token-refresh request.')
        }

        const authState: AuthState = authPayloadToAuthState(payload)

        await this.authStorageService.submitAuthState(authState)

        this.pendingTokenRefresh = undefined
        return authState.accessToken
    }
}
