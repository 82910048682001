import { AfterViewInit, Component, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core'
import { FormBuilder, FormControl, Validators } from '@angular/forms'
import {
    ElementSwitchAnimationComponent,
} from '@app/domains/ui/components/element-switch-animation/element-switch-animation.component'
import { CountryService } from '@app/services/country/country.service'
import { Router } from '@angular/router'
import Bugsnag from '@bugsnag/js'
import { Env } from '@env/environment.types'
import { delay, errorMessage } from '@lib/common.lib'
import { isNil } from 'ramda'
import { map, Observable } from 'rxjs'

interface Country {
    name: string
    value: Env.CountryCode
}

@Component({
    selector: 'app-country-select',
    templateUrl: './country-select.page.html',
    styleUrls: ['./country-select.page.scss'],
})
export class CountrySelectPage implements OnInit, AfterViewInit, OnDestroy {

    public languages = {
        'en': {
            submitButton: 'Continue',
            selectLabel: 'Select your country',
        },
        'fr': {
            submitButton: 'continuer',
            selectLabel: 'Sélectionnez votre pays',
        },
        'de': {
            submitButton: 'Weiter',
            selectLabel: 'Wählt Ihr Land',
        },
    }

    @ViewChildren(ElementSwitchAnimationComponent)
    private readonly switchAnimations: QueryList<ElementSwitchAnimationComponent>

    public control: FormControl<Env.CountryCode | null>
    public processing: boolean = false
    public countrySelected: Observable<any>

    public readonly countries: readonly Country[] = [
        { value: 'nl', name: this.countryService.countryNames.nl },
        { value: 'de', name: this.countryService.countryNames.de },
    ]

    public options = this.countries.map(country => ({
        label: country.name,
        value: country.value,
        image: `/assets/images/${country.value}.svg`,
    }))

    private intervalId: ReturnType<typeof setInterval>

    constructor(
        private readonly formBuilder: FormBuilder,
        private readonly countryService: CountryService,
        private readonly router: Router,
    ) {

    }

    public ngOnInit(): void {
        this.countrySelected = this.countryService.countryCode$.pipe(
            map(country => ! isNil(country)),
        )
        this.control = this.formBuilder.control(this.countryService.getSelectedCountryCode(), [Validators.required])
    }

    public ngAfterViewInit(): void {

        this.intervalId = setInterval(() => {
            this.switchAnimations.forEach(switchAnimation => switchAnimation.nextSlide())
        }, 3000)
    }

    public ngOnDestroy(): void {
        clearInterval(this.intervalId)
    }

    public async setSelectedCountry(): Promise<void> {
        if (! this.control.value) {
            return
        }

        this.processing = true

        try {
            await this.countryService.setCountryCode(this.control.value)

            // The delay is a hack to give connection check streams that are derived from the
            // country code stream the opportunity to update before we attempt to navigate to
            // other application pages, which should all be guarded behind a successful API
            // availability check.
            await delay(500)

            await this.router.navigateByUrl('/')
        } catch (err) {
            Bugsnag.notify(errorMessage(err))
        } finally {
            this.processing = false
        }

    }

    protected readonly Object = Object
}
