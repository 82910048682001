import { Component, DestroyRef, forwardRef, Input, OnInit } from '@angular/core'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms'
import {
    ImageSelectDialogComponent,
} from '@app/domains/ui/components/image-select-dialog/image-select-dialog.component'
import { ImageOption } from '@app/domains/ui/components/image-select/image-select.types'
import { ModalController } from '@ionic/angular'
import { BehaviorSubject } from 'rxjs'

@Component({
    selector: 'app-image-select',
    templateUrl: './image-select.component.html',
    styleUrls: ['./image-select.component.scss'],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(
                () => ImageSelectComponent,
            ),
            multi: true,
        },
    ],
})

export class ImageSelectComponent implements OnInit, ControlValueAccessor {

    public isActive$$ = new BehaviorSubject<boolean>(false)

    public selectedOption$$ = new BehaviorSubject<ImageOption>({
        label: '',
        value: '',
    })

    @Input({ required: true })
    public options: ImageOption[]

    @Input()
    public label: string

    public isDisabled: boolean = false

    public onChange: (value: string) => void | null

    public onTouched: () => void
    public touched: boolean = false

    constructor(
        private readonly modalController: ModalController,
        private readonly destroy: DestroyRef,
    ) {
    }

    ngOnInit() {
        this.selectedOption$$.pipe(
            takeUntilDestroyed(this.destroy),
        ).subscribe(selectedOption => this.onChange && this.onChange(selectedOption.value))
    }

    public async openModal() {
        if (! this.touched) {
            this.onTouched()
            this.touched = true
        }

        this.isActive$$.next(true)

        const modal = await this.modalController.create({
            component: ImageSelectDialogComponent,
            cssClass: 'max-content-modal',
            componentProps: {
                label: this.label,
                options: this.options,
                selectedOption: this.selectedOption$$.value,
            },
        })
        await modal.present()

        const { data, role } = await modal.onWillDismiss()

        this.isActive$$.next(false)

        if (role === 'save') {
            const imageOption = this.options.find(option => option.value === data)
            if (imageOption) {
                this.selectedOption$$.next(imageOption)
            }
        }
    }

    public writeValue(value: string) {
        const imageOption = this.options.find(option => option.value === value)
        if (imageOption) {
            this.selectedOption$$.next(imageOption)
        }
    }

    public registerOnChange(onChange: (value: string) => void) {
        this.onChange = onChange
    }

    public registerOnTouched(onTouched: () => void) {
        this.onTouched = onTouched
    }

    public setDisabledState(isDisabled: boolean) {
        this.isDisabled = isDisabled
    }

}
