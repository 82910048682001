<ion-content>
    <div class="ion-padding">
        <div class="header">

            @if (headerTranslationKey) {
                <ion-text color="primary">
                    <h1 class="spacious-header">{{ headerTranslationKey | translate }}</h1>
                </ion-text>
            } @else {
                <ion-text color="primary">
                    <app-element-switch-animation animationType="scroll">
                        @for (language of Object.values(languages); track language) {
                            <h1 class="spacious-header">{{ language.title }}</h1>
                        }
                    </app-element-switch-animation>
                </ion-text>
            }

            @if (withCountrySelectionLink) {
                <app-underline-button
                    routerLink="/select-country"
                    [text]="(countryService.countryName$ | async) ?? ''"
                />
            }

        </div>

        <img class="image" [src]="imageUrl" alt="" />

        <div class="links-bar" *ngIf="withSegmentButtons">
            <ion-button
                color="tertiary"
                [fill]="activeSegmentButton === 'login' ? 'solid' : 'outline'"
                [routerLink]="activeSegmentButton === 'login' ? '' : '/auth/login'"
            >
                {{ 'auth.login' | translate }}
            </ion-button>

            <ion-button
                color="tertiary"
                [fill]="registerLink.isActive ? 'solid' : 'outline'"
                routerLink="/auth/register"
                routerLinkActive
                #registerLink="routerLinkActive"
            >
                {{ 'auth.create-account' | translate }}
            </ion-button>
        </div>

        <ng-content></ng-content>
    </div>
</ion-content>
