import { Component, Input, OnInit } from '@angular/core'
import { FormControl, Validators } from '@angular/forms'
import { ImageOption } from '@app/domains/ui/components/image-select/image-select.types'
import { ModalController } from '@ionic/angular'

@Component({
    selector: 'app-image-select-dialog',
    templateUrl: './image-select-dialog.component.html',
    styleUrls: ['./image-select-dialog.component.scss'],
})
export class ImageSelectDialogComponent implements OnInit {

    @Input({ required: true })
    public options: ImageOption[]

    @Input()
    public selectedOption: ImageOption

    @Input()
    public label: string

    public control: FormControl

    constructor(
        private readonly modalController: ModalController,
    ) {
    }

    ngOnInit() {
        this.control = new FormControl<string>(this.selectedOption.value || '', [Validators.required])
    }

    save() {
        if (this.control.invalid) {
            return
        }
        this.modalController.dismiss(this.control.value, 'save')
    }

    cancel() {
        this.modalController.dismiss(null, 'cancel')
    }

}
