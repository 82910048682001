import { inject } from '@angular/core'
import { Router, UrlTree } from '@angular/router'
import { AuthService } from '@app/services/auth/auth.service'
import { CountryService } from '@app/services/country/country.service'

export async function hasCountrySelection(): Promise<boolean | UrlTree> {
    if (inject(CountryService).hasSelectedCountry()) {
        return true
    } else {
        // "Since we are unsure which cluster to connect to,
        // clear all authentication data to ensure the user
        // is prompted to log in again."
        inject(AuthService).flushAuthData()
        return inject(Router).createUrlTree(['/select-country'])
    }
}
